@tailwind base;
@tailwind components;
@tailwind utilities;
@import "prismjs/themes/prism-tomorrow.css";

html {
	scroll-behavior: smooth;
}

@media screen and (prefers-reduced-motion: reduce) {
	html {
		scroll-behavior: auto;
	}
}

.header{
	min-height: 70vh;
}

.playground-editor{
	position: relative;
}

.editor-container {
	height: 60vh;
	overflow: auto;
	tab-size: 4ch;
	color: #fff;
	background-color: #333;
}

.editorLineNumber {
	position: absolute;
	left: 0px;
	color: #8a8a8a;
	text-align: right;
	width: 40px;
}

.editor {
	counter-reset: line;
}

.editor pre{
	padding-left: 5rem !important;
	height: 100%
}

.codeArea {
	overflow-wrap: normal !important;
	word-break: keep-all !important;
	outline: none;
	padding-top: 1rem;
	padding-left: 5rem !important;
	/* z-index: 999999999; */
	white-space: nowrap;
}

.terminal {
  overflow-y: scroll;
  transition-property: max-height, padding; 
	transition-duration: 0.2s;
	transition-timing-function: ease-in-out;
  height:auto;
  max-height: 30rem;
	padding: 2rem;
	opacity: 1;
}

.terminal .output{
	opacity: 1;
	transition: opacity 0.5s;
	transition-delay: 0.3s;
}

.terminal-collapsed {
  max-height:0;
	padding:0;
	transition-property: max-height, padding; 
	transition-duration: 0.2s;
	transition-timing-function: ease-out;
}

.documentation-code {
  font-family: monospace;
}

.copied-text::after {
  position: absolute;
  right: -6px;
  top: 50%;
  transform: translateY(-50%);
  content: "";
  width: 0px;
  height: 0px;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid #666;
}